<template>
  <div class="loading-spinner" v-if="show">
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "spinner",
  props: ["show"],
  watch: {
    show: {
      handler() {
        if (this.show) {
          $("body").addClass("overflow-hidden");
        } else {
          $("body").removeClass("overflow-hidden");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000d0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
</style>