<template>
  <div id="layout-default" class="container-fluid p-0">
    <nav class="navbar navbar-expand-md navbar-light bg-white p-0">
      <a class="navbar-brand">
        <img src="@/assets/terradrone-nav.png" />
        <img src="@/assets/kjs-nav.png" />
      </a>
      <button
        class="navbar-toggler border-0 mr-2"
        type="button"
        @click="toggleMobileNavbar()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse h-100">
        <ul class="navbar-nav mr-auto h-100">
          <li class="nav-item" v-for="link in links" :key="link.name">
            <router-link
              class="nav-link"
              :class="{ 'router-link-exact-active': linkIsActive(link) }"
              :to="{ name: link.name }"
            >
              <i :class="link.icon" v-if="link.icon"></i>
              <span>{{ link.title }}</span>
            </router-link>
          </li>
        </ul>
        <div
          id="navbarUserDropdownContainer"
          class="position-relative"
          @mouseover="toggleUserDropdown('show')"
          @mouseleave="toggleUserDropdown('hide')"
        >
          <button
            class="btn btn-link text-secondary px-0 dropdown-toggle shadow-none"
            id="navbarUserDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-user-circle user-icon"></i>
            <div class="text-left pl-2">
              <div class="small text-muted">Hello</div>
              <div class="text-dark font-weight-bold">
                {{ username }}
              </div>
            </div>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right text-center w-100"
            aria-labelledby="navbarDropdown"
          >
            <a class="dropdown-item" href="#" @click.prevent="logout()">
              <i class="fas fa-sign-out-alt mr-2"></i>
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
    <div id="navbarMobileContainer" class="d-block d-md-none">
      <div id="navbarMobileContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-for="link in links" :key="link.name">
            <router-link class="nav-link" :to="{ name: link.name }">
              <i :class="link.icon" v-if="link.icon"></i>
              <span>{{ link.title }}</span>
            </router-link>
          </li>
          <li class="nav-item pt-1 bg-white"></li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logout()">
              <i class="fas fa-sign-out-alt"></i>
              <div style="line-height: 1.1">
                <div class="small text-muted">Hello, {{ username }}</div>
                Logout
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="position-relative" style="z-index: 10">
      <slot />
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import $ from "jquery";
import Spinner from "@/components/Spinner";

export default {
  name: "default-layout",
  components: {
    spinner: Spinner,
  },
  data() {
    return {
      username: null,
      isLoading: false,
      links: [
        {
          name: "dashboard",
          title: "Dashboard",
        },
        {
          name: "sites",
          title: "Sites",
          activeOnRoute: { name: "site.", exact: false },
        },
        {
          name: "settings",
          title: "Settings",
        },
      ],
    };
  },
  computed: {
    linkIsActive() {
      return (link) => {
        if (typeof link.activeOnRoute == "object") {
          if (link.activeOnRoute.exact) {
            return (
              this.$route.name && this.$route.name == link.activeOnRoute.name
            );
          } else {
            return (
              this.$route.name &&
              this.$route.name.includes(link.activeOnRoute.name)
            );
          }
        } else {
          return false;
        }
      };
    },
  },
  watch: {
    $route: {
      handler() {
        this.toggleMobileNavbar("hide");
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleSpinner(show) {
      this.isLoading = show;
    },
    toggleUserDropdown(type) {
      $("#navbarUserDropdown").dropdown(type);
    },
    toggleMobileNavbar(type) {
      if (type == "hide") {
        $("#navbarMobileContent").slideUp("fast");
        $("#navbarMobileContent").removeClass("expanded");
      } else {
        $("#navbarMobileContent").slideToggle("fast");
        $("#navbarMobileContent").toggleClass("expanded");
      }
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      this.$router.push({
        name: "login",
      });
    },
  },
  mounted() {
    const user = localStorage.getItem("user");

    if (user) {
      this.username = JSON.parse(user).email;
    }
  },
};
</script>
