import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const homeRoute = 'dashboard'
const authRoute = 'login'

let router = new Router({
  routes: [{
    path: '/',
    name: 'landing',
    redirect: {
      name: homeRoute
    }
  }, {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    }
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
      layout: 'empty'
    }
  }, {
    path: '/sites',
    name: 'sites',
    component: () => import('../views/Sites.vue'),
    meta: {
      requiresAuth: true,
    }
  }, {
    path: '/site/:id?',
    name: 'site',
    component: () => import('../views/Site.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [{
      name: "site.overview",
      path: 'overview',
      component: () => import('../views/Site/Overview.vue'),
      meta: {
        dontScroll: true
      }
    }, {
      name: "site.sharer",
      path: 'sharer',
      component: () => import('../views/Site/Sharer.vue'),
      meta: {
        dontScroll: true
      }
    }, {
      name: "site.interactive-tour",
      path: 'interactive-tour',
      component: () => import('../views/Site/InteractiveTour.vue'),
      meta: {
        dontScroll: true
      }
    }, {
      name: "site.3d-visualisation",
      path: '3d-visualisation',
      component: () => import('../views/Site/3DVisualisation.vue'),
      meta: {
        dontScroll: true
      }
    }, {
      name: "site.audit",
      path: 'audit',
      component: () => import('../views/Site/Audit.vue'),
      meta: {
        dontScroll: true
      }
    }]
  }, {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
    }
  }]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: authRoute,
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))

      if (to.matched.some(record => record.meta.isAdmin)) {
        if (user.isAdmin == 1) {
          next()
        } else {
          next({
            name: homeRoute
          })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      next({
        name: homeRoute
      })
    }
  } else {
    next()
  }

  if (from.matched.some(record => record.meta.dontScroll) && to.matched.some(record => record.meta.dontScroll)) {

  } else {
    window.scrollTo(0, 0)
  }
})

export default router