import Vue from 'vue'
import Moment from "moment"

class Helper {
	static getRatio = (num_1, num_2) => {
		for (let num = num_2; num > 1; num--) {
			if ((num_1 % num) == 0 && (num_2 % num) == 0) {
				num_1 = num_1 / num;
				num_2 = num_2 / num;
			}
		}
		let ratio = num_1 + ":" + num_2;
		return ratio;
	}

	static formatEnumString = (data) => {
		if (!data) {
			return ""
		} else {
			return data.split("_").join(" ")
		}
	}

	static formatDate = (date, withTime) => {
		if (!date) {
			return ""
		} else if (withTime) {
			return Moment(date).format("D MMM YYYY, h:mm A")
		} else {
			return Moment(date).format("D MMM YYYY")
		}
	}

	static formatMoney = (data) => {
		if (!data) {
			return "RM 0.00"
		} else {
			return `RM ${data.toFixed(2)}`
		}
	}

	static formatMediaUrl = (media) => {
		if (media && media.url) {
			let url = media.url

			return url.startsWith("http") ? url : `https://${url}`
		} else {
			return false
		}
	}

	static formatCoordinate = (coord) => {
		if (coord && typeof coord == "object" && coord.length == 2) {
			return `${parseFloat(coord[0]).toFixed(6)}, ${parseFloat(coord[1]).toFixed(6)}`
		}
		if (coord && !isNaN(coord)) {
			return `${parseFloat(coord).toFixed(6)}`
		} else {
			return ""
		}
	}

	static formatAddress = (address, city, state) => {
		let result = []

		if (address) {
			result.push(address)
		}

		if (city) {
			result.push(city)
		}

		if (state) {
			result.push(state)
		}

		return result.join(", ")
	}
}

export default {
	install: () => {
		Vue.prototype.Helper = Helper
		Vue.Helper = Helper
	}
}